
const BlankSpace = () => {
    return (


        <div className="blankspace bg-light">
            
        
        </div>

    )
}

export default BlankSpace;