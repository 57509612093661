import About from '../components/Home/About'
import Interview from '../components/Interview';


const AboutView = () => {
    return (
      <div>
      <About />
      {/* <Interview/> */}
      
      </div>
      
    )
  }

  export default AboutView;